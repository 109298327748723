import React, { useState } from "react"
import { Modal, Button } from "react-bootstrap"
import moment from "moment"
import HubspotForm from "react-hubspot-form"

import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
// font awesome library icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSearch } from "@fortawesome/free-solid-svg-icons"
import defaultImg from "../images/default-cover-1.jpg"
import BlogArchive from "./../components/blogArchive"
import { CAMPAIGN_POSTS } from "./../controlled/index"
import useLiveControl from "../controlled/useLiveControl"
import { Helmet } from "react-helmet"

const BlogList = ({ data }) => {
  const [show, setShow] = useState(false)
  // const [message, setMessage] = useState(false)
  // const { register, handleSubmit, errors } = useForm();
  const message = false
  const handleClose = () => setShow(false)

  const searchBlog = e => {
    // var searchText = e.target.value;
  }

  // const onSubmitEmail = (data, e) => {
  //   var xhr = new XMLHttpRequest()
  //   var url =
  //     "https://api.hsforms.com/submissions/v3/integration/submit/381510/a14474d3-8b5a-4392-9713-c806454971a6"
  //   var sendData = {
  //     fields: [
  //       {
  //         name: "email",
  //         value: data.email2,
  //       },
  //     ],
  //   }
  //   var finel_data = JSON.stringify(sendData)
  //   xhr.open("POST", url)
  //   xhr.setRequestHeader("Content-Type", "application/json")
  //   xhr.setRequestHeader("Access-Control-Allow-Origin", "*")
  //   xhr.setRequestHeader("Access-Control-Allow-Methods", "POST")
  //   xhr.onreadystatechange = function() {
  //     if (xhr.readyState === 4 && xhr.status === 200) {
  //       // alert(xhr.responseText.split('"', 4)[3]);
  //       setMessage(xhr.responseText.split('"', 4)[3])
  //       setShow(true)
  //       e.target.reset()
  //     } else if (xhr.readyState === 4 && xhr.status === 400) {
  //       setMessage(JSON.parse(xhr.responseText).errors[0].message)
  //       setShow(true)
  //     } else if (xhr.readyState === 4 && xhr.status === 403) {
  //       setMessage(xhr.responseText)
  //       setShow(true)
  //     } else if (xhr.readyState === 4 && xhr.status === 404) {
  //       setMessage(xhr.responseText)
  //       setShow(true)
  //     }
  //   }
  //   xhr.send(finel_data)
  // }
  const blogs = data.wpgraphql.posts.edges

  const month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]

  const d = new Date()
  const currentMonth = month[d.getMonth()]
  const prevMonth = month[(d.getMonth() + 11) % 12]
  const { isStaging } = useLiveControl()

  return (
    <Layout>
      <Seo
        title={"Blog"}
        description={
          "Welcome to the EnerBank USA blog! Learn more about how to grow your business. "
        }
      />
      <Helmet>
        <meta name="google-site-verification" content="obl_ICVxTY9NVhaUXjQ9qaq1strClQnf11AIPc3FMFA" />
      </Helmet>
      <section className={`section-gap blogpost ${isStaging ? "bg-light-gray" : null }`}>
        <div className="container">
          <div className="row mb-4">
            <div className="col-12 col-lg-6">
            {isStaging ? (
              <h1 className="RHIF-heading">Regions Home Improvement Financing Blog</h1>
            ) : ( 
              <h1 className="mb-3">EnerBank Blog</h1>
            )}
              <h4 className="font-weight-light">{`${prevMonth} | ${currentMonth}`}</h4>
            </div>
            <div className="col-12 col-lg-6">
              <div className="row">
                <div className="col-12 col-md-8 d-none">
                  <label htmlFor="serchBlog">Search Blog</label>
                  <div className="input-group">
                    <input
                      type="text"
                      name="serchBlog"
                      id="serchBlog"
                      className="form-control border-right-0"
                      placeholder="Search Blog"
                      onChange={searchBlog}
                    />
                    <div className="input-group-append">
                      <button className="btn border border-left-0">
                        <FontAwesomeIcon icon={faSearch} />
                      </button>
                    </div>
                  </div>
                </div>

                <div className="subscribe-container pr-3 ml-auto">
                  <div id="subscribe">
                  {isStaging ? (
                      null
                    ) : (
                      <label htmlFor="subscribeEmail">
                      Subscribe to Email Updates
                      </label>
                    )}
                    {/* Added Hubspot subscribe form  */}
                    <HubspotForm
                      portalId="381510"
                      formId="7a74c670-5f41-4932-9d48-a5701dc78380"
                      onSubmit={() => this.handleFormSubmit}
                      onReady={form => console.log("Form ready!")}
                      loading={<div>Loading...</div>}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-8">
              {(() => {
                for (var i = 0; i < blogs.length; i++) {
                  if (
                    blogs[i].node.blog.blogField.private === true ||
                    CAMPAIGN_POSTS.includes(blogs[i]?.node?.slug)
                    // || (!isStaging &&
                    //   DEV_ONLY_PATHS.includes("/blog/" + blogs[i]?.node?.slug))
                  ) {
                    blogs.splice(i, 1)
                  }
                }
                return (
                  <div
                    key={blogs[0].node.id}
                    className={`${isStaging ? "mb-5" : "muppet-fart"}`}
                  >
                    {blogs[0].node.featuredImage ? (
                      <a
                        href={"/blog/" + blogs[0].node.slug + "/"}
                        rel="noreferrer"
                      >
                        <img
                          src={blogs[0].node.featuredImage.node.sourceUrl}
                          alt={blogs[0].node.featuredImage.node.altText}
                          className={`${isStaging ? "image" : "" } w-100 mb-4`}
                        />
                      </a>
                    ) : (
                      <a
                        href={"/blog/" + blogs[0].node.slug + "/"}
                        rel="noreferrer"
                      >
                        <img src={defaultImg} alt={"DefaultImage"} />
                      </a>
                    )}
                    <Link
                      to={"/blog/" + blogs[0].node.slug + "/"}
                      rel="noreferrer"
                    >
                      <h3
                        className="text-blue mb-0"
                        dangerouslySetInnerHTML={{
                          __html: blogs[0].node.title,
                        }}
                      />
                    </Link>
                    <strong className="mb-2 d-inline-block">
                      {moment(blogs[0].node.date).format("MMMM D, Y")}
                    </strong>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: blogs[0].node.excerpt,
                      }}
                    />
                    <Link
                      to={"/blog/" + blogs[0].node.slug + "/"}
                      rel="noreferrer"
                      className="btn-link p-0"
                    >
                      Read More
                    </Link>
                  </div>
                )
              })()}
              <hr className="mt-4 mb-5" />
              <div className="row">
                {blogs
                  .slice(1)
                  .filter(blog => {
                    const blogDate = moment(blog.node.date)
                    let startDate = moment().add(-1, "months")
                    startDate.set("date", 1)
                    return blogDate.valueOf() > startDate.valueOf()
                  })
                  .map((item, i) => (
                    <div
                      className="col-md-6 mb-5 blog-post position-relative"
                      style={
                        item.node.blog.blogField.private === true
                          ? { display: "none" }
                          : null
                      }
                      key={item.node.id}
                    >
                      {item.node.categories.edges[0].node.name ===
                        "Leadership" && (
                        <div
                          className="position-absolute bg-blue py-1 px-2"
                          style={{ right: "15px" }}
                        >
                          {item.node.categories.edges[0].node.name}
                        </div>
                      )}
                      {item.node.featuredImage ? (
                        <a
                          href={"/blog/" + item.node.slug + "/"}
                          rel="noreferrer"
                          className="p-0"
                        >
                          <img
                            src={item.node.featuredImage.node.sourceUrl}
                            alt={item.node.featuredImage.node.altText}
                            className={`${isStaging ? "image" : "" }`}
                          />
                        </a>
                      ) : (
                        <a
                          href={"/blog/" + item.node.slug + "/"}
                          rel="noreferrer"
                        >
                          <img src={defaultImg} alt={""} />
                        </a>
                      )}
                      <a
                        href={"/blog/" + item.node.slug + "/"}
                        rel="noreferrer"
                      >
                        <h3
                          className="text-blue mt-4"
                          dangerouslySetInnerHTML={{ __html: item.node.title }}
                        />
                      </a>
                      <strong className="mb-2">
                        {moment(item.node.date).format("MMMM D, Y")}
                      </strong>
                      <div
                        dangerouslySetInnerHTML={{ __html: item.node.excerpt }}
                        className="mb-auto"
                      />
                      <a
                        href={"/blog/" + item.node.slug + "/"}
                        rel="noreferrer"
                        className="btn-link p-0"
                      >
                        Read More
                      </a>
                    </div>
                  ))}
              </div>
            </div>
            <div className="col-lg-4">
              <div className="my-3 mt-lg-0">
                <BlogArchive />
              </div>
              {isStaging ? (
              <div className="growBussiness">
                <HubspotForm
                  portalId="381510"
                  formId="e5acddb0-93f6-48b2-89d9-bd9714b2df24"
                  onSubmit={() => this.handleFormSubmit}
                  onReady={form => console.log("Form ready!")}
                  loading={<div>Loading...</div>}
                />
              </div>
              ) : (
                <div className="bg-blue rounded growBussiness px-4 py-3">
                <HubspotForm
                  portalId="381510"
                  formId="e5acddb0-93f6-48b2-89d9-bd9714b2df24"
                  onSubmit={() => this.handleFormSubmit}
                  onReady={form => console.log("Form ready!")}
                  loading={<div>Loading...</div>}
                />
              </div>
              )}
            </div>
          </div>
        </div>
      </section>
      <Modal show={show} onHide={handleClose}>
        <Modal.Body
          className="p-4 mt-5 text-center"
          dangerouslySetInnerHTML={{ __html: message }}
        />
        <Modal.Footer className="border-0">
          <Button variant="primary mb-3 mx-auto" onClick={handleClose}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </Layout>
  )
}
export default BlogList

export const query = graphql`
  query($databaseId: ID!) {
    wpgraphql {
      page(id: $databaseId, idType: DATABASE_ID) {
        title
        date
        content(format: RENDERED)
        featuredImage {
          node {
            altText
            title(format: RENDERED)
            mediaItemUrl
            slug
          }
        }
      }

      posts(first: 1000, where: { orderby: { order: DESC, field: DATE } }) {
        edges {
          node {
            id
            title
            slug
            date
            excerpt
            featuredImage {
              node {
                altText
                sourceUrl
              }
            }
            categories {
              edges {
                node {
                  name
                }
              }
            }
            blog {
              blogField {
                private
              }
            }
          }
        }
      }
    }
  }
`
